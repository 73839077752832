import React from 'react'
import PropTypes from 'prop-types'
import { Container } from './Chip.styles'

const Chip = (props) => {
  const { children } = props

  return (
    <Container>
      {children}
    </Container>
  )
}

Chip.propTypes = {
  /**
   * Children.
   */
  children: PropTypes.node.isRequired,
}


export default Chip
