import React from 'react'
import PropTypes from 'prop-types'
import { Container, Footer, Header, Main, Navigation, Sidebar } from './ChilecompaLayout.styles'

const BrandLayout = props => {
  const { children, footer, header, navigation, sidebar } = props

  return (
    <Container>
      {header && <Header>{header}</Header>}
      {navigation && <Navigation>{navigation}</Navigation>}
      <Main>{children}</Main>
      {sidebar && <Sidebar>{sidebar}</Sidebar>}
      {footer && <Footer>{footer}</Footer>}
    </Container>
  )
}

BrandLayout.defaultProps = {
  footer: null,
  header: null,
  main: null,
  navigation: null,
  sidebar: null
}

BrandLayout.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  header: PropTypes.node,
  navigation: PropTypes.node,
  sidebar: PropTypes.node
}

export default BrandLayout
