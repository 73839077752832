import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const PageName = styled.h2`
  margin-left: 1rem;
`
