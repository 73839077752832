import { Description } from './Description.styles'

export const DescriptionSection = props => {
  const { children } = props
  return (
    <Description>{children}</Description>
  )
}

export default DescriptionSection
