import styled from 'styled-components'

export const Container = styled.div`
  margin: 32px 0;
`

export const Form = styled.form``

export const Input = styled.h3`
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
  color: #566a7f;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

// TODO: this could be a component
export const Table = styled.table`
  width: 100%;
`
