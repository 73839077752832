import React from 'react'
import AdminLayout from '../../../components/Layouts/AdminLayout'
import HeaderContainer from '../containers/Header/Header.container'
import SidebarContainer from '../containers/Sidebar/Sidebar.container'
import EditProjectContainer from '../containers/Project/EditProject.container'
import Title from '../containers/MainSection/Title'
import Description from '../containers/MainSection/Description'

const EditProject = () => {
  return (
    <AdminLayout
      header={<HeaderContainer />}
      sidebar={<SidebarContainer />}
      title={<Title>Modificar Proyectos</Title>}
      description={<Description>Modifique la información de un proyecto.</Description>}
    >
      <EditProjectContainer />
    </AdminLayout>
  )
}

export default EditProject
