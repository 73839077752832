import styled from 'styled-components'

const phoneInlineSize = '200px'
const gridColumns = `1fr ${phoneInlineSize} 1080px 1fr`

export const Phone = styled.div`
  aspect-ratio: 9/16;
  background-color: red;
  grid-area: phone;
  inline-size: ${phoneInlineSize};
  inset-block-start: 0;
  position: sticky;
`

export const Grid = styled.div`
  display: grid;
  grid-template-areas: 'wrapper-start header header wrapper-end' 'wrapper-start phone content wrapper-end';
  grid-template-columns: ${gridColumns};
  grid-template-rows: 200px;
`

export const Header = styled.header`
  background-color: blue;
  grid-area: header;
  /* grid-column: 2 / 4; */
`

export const Hero = styled.div`
  background-color: aquamarine;
  grid-column: 1 / -1;
  grid-row-start: 2;
  display: grid;
  grid-template-areas: '. . content .';
  grid-template-columns: ${gridColumns};
`

export const HeroContent = styled.div`
  /* background-color: aquamarine; */
  /* grid-column: 1 / -1; */
  /* grid-row: 2; */
  /* grid-column-start: 3;
  grid-row-start: 1; */
  grid-area: content;
`

export const Paragraph = styled.p``

export const Section = styled.section`
  align-items: center;
  border: 1px solid red;
  counter-increment: section;
  display: flex;
  font-size: 3rem;
  grid-column: 1 / -1;
  justify-content: center;
  min-block-size: 400px;

  ::after {
    content: counter(section);
  }
`
