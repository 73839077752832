import React from 'react'
import PropTypes from 'prop-types'
import { Card, Body, Image } from './CardOne.styles'

const CardOne = props => {
  const {
    children,
    onClick,
    image,
    title
  } = props

  return (
    <Card onClick={onClick}>
      <Image img={image} loading='lazy' title={title}></Image>
      <Body>
        {children}
      </Body>
    </Card>
  )
}

CardOne.defaultProps = {
  image: '',
  onClick: () => undefined,
  title: ''
}

CardOne.propTypes = {
  /**
   * Children.
   */
  children: PropTypes.node.isRequired,
  /**
   * Image.
   */
  image: PropTypes.string,
  /**
   * On click.
   */
  onClick: PropTypes.func
}

export default CardOne
