import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onGetApplicationsAsync } from '../../../config/store/application.slice'
import * as Styled from './Footer.styles'

const Footer = () => {
  const { loading, application } = useSelector(state => state.applications)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(onGetApplicationsAsync())
  }, [dispatch])

  return (
    <Styled.Container>
      <Styled.Environment>{process.env.REACT_APP_ENVIRONMENT}</Styled.Environment>
      { !loading && <Styled.Year>© { application['app_copyright_name'] } - { application['app_copyright_year'] } </Styled.Year> }
      <Styled.Version>{ application['app_version'] }</Styled.Version>
    </Styled.Container>
  )
}
 
export default Footer
