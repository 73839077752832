import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const UserIcon = styled.img`
  border-radius: 50px;
  cursor: pointer;
  height: 38px;
  margin-right: 1rem; 
  width: 38px;
`

export const LogoutContainer = styled.div`
  text-align: right;
`

export const UserContainer = styled.div``

export const Username = styled.span``