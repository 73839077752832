export { default as CircularLoadingIcon } from './AiOutlineLoading3QuartersIcon'
export { default as Css3Icon } from './FaCss3Icon'
export { default as FaBarsIcon } from './FaBarsIcon'
export { default as GithubIcon } from './FaGithubIcon'
export { default as Html5Icon } from './FaHtml5Icon'
export { default as InfinityIcon } from './FaInfinityIcon'
export { default as ReactIcon } from './FaReactIcon'
export { default as WordpressIcon } from './FaWordpressIcon'

export * as IconUtils from './utils'
