import styled from 'styled-components'

export const Content = styled.div`
  transition: margin-left 300ms;
  margin-left: 250px;
  color: ${props => props.theme.text};
`

export const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  padding: 2rem 1.5rem;
  min-height: calc(100vh - 60px);
  background: ${props => props.theme.mainWrapperBackground};
  // max-width: 1440px; TODO: implement this later
`

export const Title = styled.span`
  display: inline-block;
  padding-right: 1rem;
`
