import React from 'react'
import Layout from '../../../components/Layouts/AdminLayout'
import HeaderContainer from '../containers/Header/Header.container'
import SidebarContainer from '../containers/Sidebar/Sidebar.container'
import ProfileContainer from '../containers/Profile/Profile.container'
import Title from '../containers/MainSection/Title'

const Project = () => {
  return (
    <Layout
      header={<HeaderContainer />}
      sidebar={<SidebarContainer />}
      title={<Title>Perfil de usuario</Title>}
      description='Revisa la información de tu perfil de usuario.'
    >
      <ProfileContainer />
    </Layout>
  )
}

export default Project
