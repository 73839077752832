import React from 'react'
import { Container } from './Header.styles'
import Brand from './Brand.container'
import User from './User.container'

const Header = () => {
  return (
    <Container>
      <Brand />
      <User />
    </Container>
  )
}

export default Header
