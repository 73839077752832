import React from 'react'
import { Content, MainContent }from './AdminLayout.styles'

const AdminLayout = (props) => {
  const {
    children,
    description,
    header,
    sidebar,
    title
  } = props

  return (
    <>
      {sidebar}
      <Content>
        {header}
        <MainContent>
          {title}
          {description}
          {children}
        </MainContent>
      </Content>
    </>
  )
}

export default AdminLayout
