import React from 'react'
import { FaReact } from 'react-icons/fa';

const FaReactIcon = (props) => {
  const { title, size } = props

  return (
    <FaReact title={title} size={size} />
  )
}

export default FaReactIcon
