import React from 'react'
import { Container, Loading } from './Spinner.styles'

const Spinner = (props) => {
  return (
    <Container>
      <Loading />
    </Container>
  )
}

export default Spinner
