import styled from 'styled-components'

export const Container = styled.header`
  align-items: center;
  background: ${props => props.theme.sidebarWrapperBackground};
  color: ${props => props.theme.text4};
  display: flex;
  justify-content: space-between;
  padding: 10px 16px 10px 0;
  position: fixed;
  left: 250px;
  width: calc(100% - 250px);
  top: 0;
`
