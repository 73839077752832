import { useContext } from "react"
import AuthProviderContext from "./AuthProvider.context"

export const useAuthProvider = () => {
  const {
    isAuthenticated,
    loading,
    login,
    logout,
    user,
    accessToken,
    error
  } = useContext(AuthProviderContext)

  return {
    isAuthenticated,
    loading,
    login,
    logout,
    user,
    accessToken,
    error
  }
}
