import React from 'react'
import AdminLayout from '../../../components/Layouts/AdminLayout'
import HeaderContainer from '../containers/Header/Header.container'
import SidebarContainer from '../containers/Sidebar/Sidebar.container'
import AddNavigationContainer from '../containers/Navigation/AddNavigation.container'
import Title from '../containers/MainSection/Title'
import Description from '../containers/MainSection/Description'

const AddNavigation = () => {
  return (
    <AdminLayout
      header={<HeaderContainer />}
      sidebar={<SidebarContainer />}
      title={<Title>Administración de Navegación</Title>}
      description={<Description>Agregue, modifique o elimine menús.</Description>}
    >
      <AddNavigationContainer />
    </AdminLayout>
  )
}

export default AddNavigation
