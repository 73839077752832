import styled from 'styled-components'

export const Input = styled.input`
  letter-spacing: 0.5px;
  display: block;
  height: 40px;
  width: 100%;
  border: 1px solid #d9dee3; // TODO: this color should be a variable
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 300;
  color: #566a7f;
`
