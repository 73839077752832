import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { onEditNavigationAsync } from './Navigation.slice'
import { default as Button } from '../../../../components/Buttons/BaseButton'
import { default as Input } from '../../../../components/Inputs/BaseInput'
import { default as Label } from '../../../../components/Basics/Label'
import routes from '../../../../config/settings/routes'
import { Container, Form } from './Navigation.styles'

const NavigationEdit = () => {
  const { navigationToEdit } = useSelector(state => state.adminNavigation)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [id] = useState(navigationToEdit?.id)
  const [name, setName] = useState(navigationToEdit?.name)
  const [path, setPath] = useState(navigationToEdit?.path)
  const [styles, setStyles] = useState(navigationToEdit?.styles)
  const [isActive, setIsActive] = useState(navigationToEdit?.isActive)

  const handleName = event => setName(event.target.value);
  const handlePath = event => setPath(event.target.value);
  const handleStyles = event => setStyles(event.target.value);
  const handleIsActive = event => setIsActive(event.target.value);

  const handleEditNavigation = event => {
    event.preventDefault()
    const navigationOptionIsActive = String(isActive).toLowerCase() === 'true'
    const updatedNavigation = { name, path, styles, isActive: navigationOptionIsActive }

    dispatch(onEditNavigationAsync({ id, updatedNavigation }))
    navigate(routes.adminNavigation)
  }

  const navigationStatesOptions = [
    {
      label: 'Activo',
      value: true
    },
    {
      label: 'Inactivo',
      value: false
    }
  ]

  return (
    <Container>
      <Form onSubmit={handleEditNavigation}>
        <Label>Nombre</Label>
        <Input onChange={handleName} placeholder='nombre' defaultValue={name} />
        <Label>Ruta</Label>
        <Input onChange={handlePath} placeholder='ruta' defaultValue={path} />
        <Label>Estilos</Label>
        <Input onChange={handleStyles} placeholder='estilos' defaultValue={styles} />
        {/* TODO: change html for a styled-component */}
        <div>
          {/* TODO: change for a switch component, reference https://mui.com/material-ui/react-switch/ */}
          <Label>Estado</Label>
          <select onChange={handleIsActive} name="navigationStates" id="navigationStates" value={isActive}>
            {navigationStatesOptions.map(option => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>
        <Button margin="30px 0" type='submit'>Guardar</Button>
      </Form>
    </Container>
  )
}

export default NavigationEdit
