import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getApplications } from '../../services/applications'
import { listToDict } from '../resources/utils';

const initialState = {
  currentRequestId: undefined,
  error: null,
  loading: false,
  application: [],
  status: 'idle'
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const onGetApplicationsAsync = createAsyncThunk('application', async () => {
    const { data } = await getApplications()
 
    // The data we return becomes the `fulfilled` action payload
    return data
  }
);

export const applicationSlice = createSlice({
  name: 'not-used?',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(onGetApplicationsAsync.pending, (state) => {
        state.loading = true
        state.status = 'pending'
      })
      .addCase(onGetApplicationsAsync.fulfilled, (state, action) => {
        state.loading = false
        state.status = 'fulfilled'
        state.application = listToDict(action.payload)
      })
      .addCase(onGetApplicationsAsync.rejected, (state, action) => {
        const { requestId } = action.meta

        if (state.status === 'pending' && state.currentRequestId === requestId) {
          state.loading = false
          state.status = 'rejected'
          state.error = action.error
          state.currentRequestId = undefined
        }
      })
  }
})

export default applicationSlice.reducer
