import { REACT_APP_API_BASE_URL } from '../../../config/settings/environment'
import { factoryService, deleteJsonRequest, getJsonRequest, postJsonRequest, putJsonRequest } from '../../../core/requests-v2'

/**
 * Gets the list of navigation options.
 */
export const getNavigation = factoryService(({ token }) => {
  return getJsonRequest(`${REACT_APP_API_BASE_URL}/navigation`)
})

/**
 * Create a new navigation option.
 */
export const createNavigation = factoryService((data) => {
  const body = { ...data }

  return postJsonRequest(`${REACT_APP_API_BASE_URL}/navigation`, { body })
})

/**
 * Delete a navigation option.
 */
export const deleteNavigation = factoryService(({ id }) => {
  return deleteJsonRequest(`${REACT_APP_API_BASE_URL}/navigation/${id}`)
})

/**
 * Update a navigation option.
 */
export const updateNavigation = factoryService(({ id, updatedNavigation, accessToken }) => {
  const body = {
    name: updatedNavigation.name,
    path: updatedNavigation.path,
    styles: updatedNavigation.styles,
    isActive: updatedNavigation.isActive
  }

  return putJsonRequest(`${REACT_APP_API_BASE_URL}/navigation/${id}`, { body })
})
