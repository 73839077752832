import styled from 'styled-components'
import breakpoints from '../../config/settings/breakpoints'

export const Card = styled.div`
  display: grid;
  grid-template-columns: min-content(150px);
  grid-template-rows: 120px auto;
  grid-template-areas: 'image' 'text';

  border-radius: 10px;
  border: 1px solid ${props => props.theme.text2};
  background: ${props => props.theme.background2};
  text-align: center;

  transition: 0.4s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }

  @media ${breakpoints.device.sm} {
    grid-template-rows: 200px auto;
  }
`

export const Image = styled.div`
  grid-area: image;
  background: url(${props => props.img});
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-size: cover;
`

export const Stats = styled.div`
  grid-area: stats;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: rgb(255, 7, 110);
`

export const Span = styled.span``

export const Body = styled.div`
  grid-area: text;
`

export const Stat = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  color: white;

  &:nth-child(2) {
    border-left: 1px solid rgb(172, 26, 87);
    border-right: 1px solid rgb(172, 26, 87);
  }
`

export const StatValue = styled.div`
  font-size: 22px;
  font-weight: 500;
`

export const StatValueSup = styled.sup`
  font-size: 12px;
`

export const StatType = styled.div`
  font-size: 11px;
  font-weight: 300;
  text-transform: uppercase;
`
