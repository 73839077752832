import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { 
  REACT_APP_FEATURE_FLAG_ABOUT_ME_SECTION,
  REACT_APP_FEATURE_FLAG_API_STATUS_SECTION,
  REACT_APP_FEATURE_FLAG_PROJECT_SECTION
} from '../../config/settings/environment'

/**
 * The ProtectByFeatures' component.
 */
const ProtectByFeatures = props => {
  const { children, fallback, redirectTo, rule } = props
  const navigate = useNavigate()

  const features = {
    'about.me.section':
      REACT_APP_FEATURE_FLAG_ABOUT_ME_SECTION === true || REACT_APP_FEATURE_FLAG_ABOUT_ME_SECTION === 'true',
    'api.status.section':
      REACT_APP_FEATURE_FLAG_API_STATUS_SECTION === true || REACT_APP_FEATURE_FLAG_API_STATUS_SECTION === 'true',
    'project.section':
      REACT_APP_FEATURE_FLAG_PROJECT_SECTION === true || REACT_APP_FEATURE_FLAG_PROJECT_SECTION === 'true'
  }

  // FIXME: the features should be filled from the database
  const result = rule(features)

  if (!result && redirectTo) {
    navigate(redirectTo, { replace: true })
  }

  if (!result) {
    return fallback(features)
  }

  return <>{children}</>
}

ProtectByFeatures.defaultProps = {
  fallback: () => null,
  redirectTo: '',
  rule: () => true
}

ProtectByFeatures.propsTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.func,
  redirectTo: PropTypes.string,
  rule: PropTypes.func
}

export default ProtectByFeatures
