import { IconContext } from 'react-icons'
import { useTheme } from 'styled-components'

const ConfigIcon = (props) => {
  const { children } = props
  const theme = useTheme()

  return (
    <IconContext.Provider value={{ color: theme.color, style: { cursor: 'pointer' } }}>
      <div>
        {children}
      </div>
    </IconContext.Provider>
  )
}

export default ConfigIcon
