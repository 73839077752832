import { cancelingSingleton } from '../backpressure/canceling'
import getLogger from '../debuggers/logger'
import customizeError from '../errors/custom'

const logger = getLogger('Services')

/**
 * Factory to create a wrapper to call services.
 *
 * Generate a wrapper with the following signature:
 *
 *  interface Args {
 *    actionType?: string;
 *    [key: string]: any;
 *  }
 *  interface Response {
 *    data: object;
 *    headers: object;
 *    request: object;
 *    status: number;
 *  }
 *
 *  type ServiceWrapper = (args: Args) => Promise<Response>
 *
 * E.g.
 *
 *  getService('https://api.example.com/auth', { id: 2, ... })
 */
const factoryService = service => {
  const canceling = cancelingSingleton()
  let noop = null

  return serviceArgs => {
    const { actionType, ignoreCancelingError = false, ...requestArgs } = serviceArgs || {}
    const request = service(requestArgs)

    if (ignoreCancelingError) {
      // We'll clean the reference in case of existing any promise being executing.
      noop = null
    }

    canceling.run(actionType)

    return request(actionType, canceling).catch(error => {
      if (ignoreCancelingError && (error.code === 'ERR_CANCELED' || error.code === 'ERR_ABORTED')) {
        // We do a trick to break the execution of the flow using a promise that never completes.
        // Next time the service runs, the reference to that promise will be removed, and that way
        // the garbage collector will be able to free that memory location.
        noop = null
        noop = new Promise(() => undefined)

        return noop
      }

      const serviceError = customizeError({ originalError: error, reason: 'SERVICE_ERROR' })

      logger.debug('SERVICE', { ...serviceArgs, error: serviceError })
      throw serviceError
    })
  }
}

export default factoryService
