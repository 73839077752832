import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { default as Button } from '../../../../components/Buttons/BaseButton'
import { default as CustomInput } from '../../../../components/Inputs/CustomInput.jsx'
import { onCreateNavigationAsync } from './Navigation.slice'
import routes from '../../../../config/settings/routes.js'
import { Container, Form } from './Navigation.styles.js'

const AddNavigation = () => {
  const [navigation, setNavigation] = useState({
    name: '',
    path: '',
    styles: ''
  })
  
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleChange = event => {
    const {name, value} = event.target
    setNavigation(inputs => ({...inputs, [name]: value}))
  }

  const handleSaveNavigation = event => {
    event.preventDefault()

    dispatch(onCreateNavigationAsync(navigation))
    navigate(routes.navigation)
  }

  return (
    <Container>
      <Form onSubmit={handleSaveNavigation}>
        <CustomInput
          label='Nombre'
          name='name'
          onChange={handleChange}
          placeholder='nombre'
        />
        <CustomInput
          label='Ruta'
          name='path'
          onChange={handleChange}
          placeholder='ruta'
        />
        <CustomInput
          label='Estilos'
          name='styles'
          onChange={handleChange}
          placeholder='estilos'
        />
        <Button 
          margin='16px 0' 
          type='submit'
        >
          Guardar
        </Button>
      </Form>
    </Container>
  )
}

export default AddNavigation
