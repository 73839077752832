import React from 'react'
import * as Styled from './Sticky.styles'

const Layout = () => {
  return (
    <>
      <Styled.Grid>
        <Styled.Header></Styled.Header>
        <Styled.Phone></Styled.Phone>
        <Styled.Hero>
          <Styled.HeroContent>
            <Styled.Paragraph>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore in a, ratione voluptas cupiditate
              nesciunt eos earum provident at explicabo quidem. Voluptatem ipsam modi ratione, vitae tempora omnis minus
              nesciunt.
            </Styled.Paragraph>
          </Styled.HeroContent>
        </Styled.Hero>
        <Styled.Section></Styled.Section>
        <Styled.Section></Styled.Section>
        <Styled.Section></Styled.Section>
        <Styled.Section></Styled.Section>
        <Styled.Section></Styled.Section>
      </Styled.Grid>
      <Styled.Section></Styled.Section>
      <Styled.Section></Styled.Section>
      <Styled.Section></Styled.Section>
    </>
  )
}

export default Layout
