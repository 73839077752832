import { REACT_APP_API_BASE_URL } from '../../../config/settings/environment'
import { getJsonRequest, postJsonRequest, factoryService } from '../../../core/requests-v2'

/**
 * Get a user's token.
 */
export const getAuth = factoryService(({ username, password }) => {
  const body = {
    username,
    password
  }

  return postJsonRequest(`${REACT_APP_API_BASE_URL}/auth/login`, { body, isPublic: true })
})

/**
 * Get a user's profile.
 */
export const getProfile = factoryService(() => {
  return getJsonRequest(`${REACT_APP_API_BASE_URL}/auth/profile/v4`)
})
