import React from 'react'
import PropTypes from 'prop-types'

const HomeLayout = props => {
  const { children } = props

  return <div className="container">{children}</div>
}

HomeLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default HomeLayout
