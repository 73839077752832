import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import { createNavigation, deleteNavigation, getNavigation, updateNavigation } from '../../services/navigation'

const initialState = {
  error: null,
  navigations: [],
  loading: false,
  status: 'idle'
}

/**
 * Gets the list of navigation options.
 */
export const onGetNavigationsAsync = createAsyncThunk('adminNavigation/getNavigations', async () => {
  const { data } = await getNavigation()
  return data
})

/**
 * Create a new navigation option.
 */
export const onCreateNavigationAsync = createAsyncThunk('adminProject/createNavigation', async ({ ...payload }, { dispatch }) => {
  await createNavigation(payload)
  dispatch(onGetNavigationsAsync())
})

/**
 * Delete a navigation option.
 */
export const onDeleteNavigationAsync = createAsyncThunk('adminNavigation/deleteNavigation', async ({ id }, { dispatch }) => {
  const response = await deleteNavigation({ id })
  dispatch(onGetNavigationsAsync())
  return response
})

/**
 * Update a navigation option.
 */
export const onEditNavigationAsync = createAsyncThunk('adminNavigation/editNavigation', async ({ id, updatedNavigation }, { getState, dispatch }) => {
  const state = getState();
  const response = await updateNavigation({ id, updatedNavigation })
  const index = state.adminNavigation.navigations.findIndex(item => item.id === id)
  
  if (index !== -1) {
    const updatedNavigations = [...state.adminNavigation.navigations]
    updatedNavigations[index] = { ...updatedNavigations[index], ...updatedNavigation }

    return updatedNavigations
  }

  return response
})

export const navigationSlice = createSlice({
  name: 'adminNavigation',
  initialState,
  reducers: {

    goToEditNavigation(state, action) {
      console.log('state', state, 'action', action)
      
      const { id } = action.payload
      const { navigations } = current(state)
      state.navigationToEdit = navigations.find(navigation => navigation.id === id)
    }

  },
  extraReducers: (builder) => {
    
    builder.addCase(onGetNavigationsAsync.fulfilled, (state, action) => {
      state.loading = false
      state.status = 'fulfilled'
      state.navigations = action.payload
    })

    builder.addCase(onCreateNavigationAsync.fulfilled, (state) => {
      state.loading = false
      state.status = 'fulfilled'
    })

    builder.addCase(onDeleteNavigationAsync.fulfilled, (state, action) => {
      state.navigations = state.navigations.filter((navigation) => navigation.id !== action.payload.id)
      state.status = 'fulfilled'
    })

    builder.addCase(onEditNavigationAsync.fulfilled, (state, action) => {
      state.navigations = action.payload
      state.status = 'fulfilled'
    })

  }
})

export const { goToEditNavigation } = navigationSlice.actions

export default navigationSlice.reducer
