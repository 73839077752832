import { Container, Wrapper } from './LoginLayout.styles'

const LoginLayout = props => {
  const { children } = props

  return (
    <Wrapper>
      <Container>
        {children}
      </Container>
    </Wrapper>
  )
}

export default LoginLayout
