import React from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

const AiOutlineLoading3QuartersIcon = (props) => {
  const { title, size } = props

  return (
    <AiOutlineLoading3Quarters title={title} size={size} />
  )
}

export default AiOutlineLoading3QuartersIcon
