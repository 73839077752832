import React from 'react'
import AdminLayout from '../../../components/Layouts/AdminLayout'
import HeaderContainer from '../containers/Header/Header.container'
import SidebarContainer from '../containers/Sidebar/Sidebar.container'
import AddProjectContainer from '../containers/Project/AddProject.container'
import Title from '../containers/MainSection/Title'
import Description from '../containers/MainSection/Description'

const AddProject = () => {
  return (
    <AdminLayout
      header={<HeaderContainer />}
      sidebar={<SidebarContainer />}
      title={<Title>Agregar Proyecto</Title>}
      description={<Description>Agregue un nuevo proyecto.</Description>}
    >
      <AddProjectContainer />
    </AdminLayout>
  )
}

export default AddProject
