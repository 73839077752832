import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAuth, getProfile } from '../../services/auth'
import { deleteCookie, putCookie } from '../../../../core/storages/cookies'

const initialState = {
  id: undefined,
  isAuthenticated: false,
  loading: false,
  user: {},
  error: null,
  status: 'idle'
}

/**
 * Gets the login information
 */
export const onGetAuthAsync = createAsyncThunk('auth/login', async (user) => {
  const { data } = await getAuth(user)
  return data
})

/**
 * Gets the user's profile.
 */
export const onGetProfileAsync = createAsyncThunk('auth/profile', async () => {
  const { data } = await getProfile()
  return data
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false
      state.user = {}
      state.error = null

      deleteCookie('access_token')
      deleteCookie('is_authenticated')
    }
  },
  extraReducers: (builder) => {
    builder.addCase(onGetAuthAsync.pending, (state) => {
      state.loading = true
      state.status = 'pending'
    })

    builder.addCase(onGetAuthAsync.fulfilled, (state, action) => {
      const { access_token: accessToken } = action.payload
      state.isAuthenticated = true
      state.loading = false
      state.status = 'idle'

      deleteCookie('access_token')
      deleteCookie('is_authenticated')
      
      putCookie('access_token', accessToken)
      putCookie('is_authenticated', true)
    })

    .addCase(onGetAuthAsync.rejected, (state, action) => {
      state.loading = false
      state.status = 'rejected'
      state.error = action.error
    })

    builder.addCase(onGetProfileAsync.fulfilled, (state, action) => {
      state.user = action.payload.user
      state.isAuthenticated = true
    })
  }
})

export const { logout } = authSlice.actions

export default authSlice.reducer
