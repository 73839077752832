import React from 'react'
import AdminLayout from '../../../components/Layouts/AdminLayout'
import HeaderContainer from '../containers/Header/Header.container'
import SidebarContainer from '../containers/Sidebar/Sidebar.container'
import NavigationEditContainer from '../containers/Navigation/NavigationEdit.container'
import Title from '../containers/MainSection/Title'
import Description from '../containers/MainSection/Description'

const NavigationEdit = () => {
  return (
    <AdminLayout
      header={<HeaderContainer />}
      sidebar={<SidebarContainer />}
      title={<Title>Modificar un menú</Title>}
      description={<Description>Modificar un menú.</Description>}
    >
      <NavigationEditContainer />
    </AdminLayout>
  )
}

export default NavigationEdit
