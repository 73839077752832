import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
  }

  body {
    background: ${({ theme }) => theme.body};
    color: ${props => props.theme.color};
    font-family: ${props => props.theme.fontFamily}, sans-serif;
  }
  
  @font-face {
    font-family: ${props => props.theme.fontFamily};
    src: url('../fonts/Acme/Acme-Regular.ttf') format('truetype'); // FIXME: This code can be improved, the font should depend on the theme
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: ${props => props.theme.fontFamily};
    src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype'); // FIXME: This code can be improved, the font should depend on the theme
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
`

export const lightTheme = {
  background: '#FFFFFF',
  background2: '#FFFFFF',
  sidebarWrapperBackground: '#191B22',
  mainWrapperBackground: '#f1f5f9',
  color: '#0D1117',
  fontFamily: 'Poppins',
  text: '#21262D',
  text2: '#343A40',
  text3: '#21262D',
  text4: '#8390A2'
}

export const darkTheme = {
  background: '#21262D',
  background2: '#343A40',
  sidebarWrapperBackground: '#121117',
  mainWrapperBackground: '#000000',
  color: '#FFFFFF',
  fontFamily: 'Poppins',
  text: '#21262D',
  text2: '#343A40',
  text3: '#FFFFFF',
  text4: '#8390A2'
}

export default GlobalStyle
