import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { onGetProjectsAsync } from './Project.slice'
import CardOne from '../../../components/Cards/CardOne'
import Chip from '../../../components/Chips/Chip'
import { IconUtils } from '../../../components/Icons'
import Spinner from '../../../components/Spinners/Spinner'
import { CardBody, Container, CardChips, CardTitle, CardDescription, Title, Wrapper } from './Project.styles'

const Project = () => {
  const { error, loading, projects = [] } = useSelector(state => state.projects)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleOnView = (link, type) => () => {
    if (type === 'external') {
      window.open(`${link}`, '_blank')
      return
    }

    navigate(`${link}`)
  }

  useEffect(() => {
    dispatch(onGetProjectsAsync())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Title>Proyectos</Title>
      {loading && <Spinner />}
      {!loading && error && (<p>Error cargando sección proyectos</p>)}
      {!loading && (
        <Wrapper>
          {projects.map(({description, id, image, link, name, technologiesArray, type}) => (
            <CardOne
              key={id}
              image={`images/portafolio/${image}`}
              onClick={handleOnView(link, type)}
            >
              <CardBody>
                  <CardChips>
                    {technologiesArray?.map((tech) => {
                      // console.log('t', tech) example about a console.log inside of jsx
                      return (
                        tech !== '' &&
                        <Chip key={tech}>{IconUtils.renderIcon(tech)}</Chip>
                      )
                    })}
                  </CardChips>
                <CardTitle>{name}</CardTitle>
                <CardDescription>{description}</CardDescription>
              </CardBody>
            </CardOne>
          ))}
        </Wrapper>
      )}
    </Container>
  )
}

export default Project
