import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getProjects } from '../../../services/projects'
import { stringToList } from '../../../config/resources/utils';

const initialState = {
  currentRequestId: undefined,
  error: null,
  loading: false,
  projects: [],
  status: 'idle'
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const onGetProjectsAsync = createAsyncThunk('project/getProjects', async () => {
    const { data } = await getProjects();
 
    // The data we return becomes the `fulfilled` action payload
    return data;
  }
);

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    
    builder.addCase(onGetProjectsAsync.pending, (state) => {
      state.loading = true
      state.status = 'pending'
    })
    
    builder.addCase(onGetProjectsAsync.fulfilled, (state, action) => {
      state.loading = false
      state.status = 'fulfilled'

      action.payload.map(project => {
        project.technologiesArray = stringToList(project.technologies.toUpperCase(), "|")

        return project
      })

      state.projects = action.payload
    })
    
    builder.addCase(onGetProjectsAsync.rejected, (state, action) => {
      state.loading = false
      state.status = 'rejected'
      state.error = action.error
      state.currentRequestId = undefined
    })

  }
})

export default projectSlice.reducer
