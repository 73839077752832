import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getNavigation } from "../../services/navigation"

const initialState = {
  error: null,
  navigationOptions: [],
  loading: false,
  status: 'idle'
}

export const onGetNavigationAsync = createAsyncThunk('sidebar', async () => {
  const { data } = await getNavigation()
  return data
})

export const navigationSlice = createSlice({
  name: 'sidebar-items',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(onGetNavigationAsync.pending, (state) => {
        state.loading = true
        state.status = 'pending'
      })
      .addCase(onGetNavigationAsync.fulfilled, (state, action) => {
        state.loading = false
        state.status = 'fulfilled'
        state.navigationOptions = action.payload
      })
      .addCase(onGetNavigationAsync.rejected, (state, action) => {
        state.loading = false
        state.status = 'rejected'
        state.error = action.error
        state.currentRequestId = undefined
      })
  }
})

export default navigationSlice.reducer
