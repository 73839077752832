import React from 'react'
import AdminLayout from '../../../components/Layouts/AdminLayout'
import Card from '../../../components/Cards/Card'
import HeaderContainer from '../containers/Header/Header.container'
import SidebarContainer from '../containers/Sidebar/Sidebar.container'
import Title from '../containers/MainSection/Title'
import Description from '../containers/MainSection/Description'
import { CardsWrapper } from './Dashboard.styles'

const Home = () => {
  return (
    <AdminLayout
      header={<HeaderContainer />}
      sidebar={<SidebarContainer />}
      title={<Title>Dashboard</Title>}
      description={<Description>Hola, estos son tus indicadores del día de hoy.</Description>}
    >
      <CardsWrapper>
        <Card counter='44' css='las la-users' name='Customers' />
        <Card counter='79' css='las la-clipboard-list' name='Projects' />
        <Card counter='124' css='las la-shopping-bag' name='Orders' />
        <Card counter='$6k' css='las la-google-wallet' name='Income' />
      </CardsWrapper>
    </AdminLayout>
  )
}

export default Home
