
import styled from 'styled-components'

export const Container = styled.aside`
  width: 250px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  color: ${props => props.theme.text4};
  background: ${props => props.theme.sidebarWrapperBackground};
`

export const SidebarBrand = styled.div`
  margin: 18px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const SidebarMenuContainer = styled.div`
  margin-top: 8px;
  overflow-y: auto;
  height: calc(100vh - 84px);
`

export const Icon = styled.span`
  color: ${props => props.theme.text4};
  width: 30px;
  display: inline-block;
  padding-right: 1rem;
`

export const BrandName = styled.h2`
  color: ${props => props.theme.text4};
  display: inline-block;
  padding-right: 1rem;
`

export const LinkName = styled.span`
  color: ${props => props.theme.text4};
`

export const Nav = styled.nav`
  margin-top: 20px;
`

export const NavbarLinkContainer = styled.ul``

export const NavbarMenu = styled.li`
  width: 100%;
  margin-bottom: 1.3rem;
  padding-left: 2rem;
`
