import styled from 'styled-components'

export const Wrapper = styled('div')`
  max-width: 1024px;
  margin: 20px auto 50px;
`

export const TitleH1 = styled('h1')`
  font-size: 40px;
  font-weight: bold;
`

export const TitleH2 = styled('h2')`
  font-size: 32px;
  margin-top: 20px;
`

export const TitleH3 = styled('h3')`
  margin-top: 20px;
`

export const TitleH4 = styled('h4')`
  margin-top: 20px;
`
