import React from 'react'
import PropTypes from 'prop-types'
import { Container, Header, Footer } from './LandingLayout.styles'

const LandingLayout = props => {
  const { children, footer, header } = props

  return (
    <Container>
      {header && <Header>{header}</Header>}
      {children}
      {footer && <Footer>{footer}</Footer>}
    </Container>
  )
}

LandingLayout.defaultProps = {
  footer: null,
  header: null,
  main: null
}

LandingLayout.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  header: PropTypes.node
}

export default LandingLayout
