import { Button } from './BaseButton.styles'

const BaseButton = props => {
  const {
    children,
    disabled,
    onClick,
    type,
    margin,
    width
  } = props

  return (
    <Button
      disabled={disabled}
      type={type}
      onClick={onClick}
      margin={margin}
      width={width}
    >
      {children}
    </Button>
  )
}

export default BaseButton
