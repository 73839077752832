import React from 'react'
import LoginContainer from '../containers/Login/Login.container'
import LoginLayout from '../../../components/Layouts/LoginLayout/LoginLayout'

const Login = () => {
  return (
    <LoginLayout>
      <LoginContainer />
    </LoginLayout>
  )
}

export default Login
