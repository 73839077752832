import { Link as NavLink } from './Link.styles'

const Link = props => {
  const { children, path } = props

  return (
    <NavLink
      className={({ isActive }) => isActive ? "active" : undefined}
      to={path}
    >
      {children}
    </NavLink>
  )
}

export default Link
