import React from 'react'
import { useNavigate } from 'react-router-dom'
import AdminLayout from '../../../components/Layouts/AdminLayout'
import { default as Button } from '../../../components/Buttons/BaseButton'
import HeaderContainer from '../containers/Header/Header.container'
import SidebarContainer from '../containers/Sidebar/Sidebar.container'
import NavigationListContainer from '../containers/Navigation/NavigationList.container'
import Title from '../containers/MainSection/Title'
import Description from '../containers/MainSection/Description'

const NavigationList = () => {
  const navigate = useNavigate()
  const handleCreateNavigation = () => navigate('new')

  return (
    <AdminLayout 
      header={<HeaderContainer />} 
      sidebar={<SidebarContainer />}
      title={<Title>Administración de Menús</Title>}
      description={<Description>Agregue, modifique o elimine menús de navegación.</Description>}
    >
      <Button onClick={handleCreateNavigation}>Agregar menú de navegación</Button>
      <NavigationListContainer />
    </AdminLayout>
  )
}

export default NavigationList
