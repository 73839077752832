import { Input } from "./BaseInput.styles"

/**
 * The BaseInput' component.
 * @deprecated Use CustomInput instead.
 * The BaseInput component is deprecated and will be removed in future releases.
 * The reason is because the new CustomInput component include a label on it.
 */
const BaseInput = props => {
  const {
    defaultValue,
    name,
    onChange,
    onlick,
    placeholder,
    type
  } = props

  return (
    <Input 
      defaultValue={defaultValue}
      name={name}
      onChange={onChange}
      onClick={onlick} 
      placeholder={placeholder}
      type={type} 
    />
  )
}

export default BaseInput
