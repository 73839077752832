import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  grid-area: footer-content;
  justify-content: space-between;
  margin-block-end: 1rem;
  margin-block-start: 3rem;
  margin-inline-end: 1rem;
  margin-inline-start: 1rem;
`

export const Year = styled.span``

export const Version = styled.span``

export const Environment = styled.span``
