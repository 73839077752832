import React from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { REACT_APP_LOGOUT_REDIRECT } from '../../../config/settings/environment'
import { RiLogoutCircleLine } from 'react-icons/ri';

const Logout = () => {
  const { logout } = useAuth0()
  const handleLogout = () => logout({ returnTo: `${REACT_APP_LOGOUT_REDIRECT}` })

  return (
    <RiLogoutCircleLine onClick={handleLogout} title='Cerrar Sesión' size='42' />
  )
}

export default Logout
