import React from 'react'
import { FaCss3 } from 'react-icons/fa'

const FaCss3Icon = (props) => {
  const { title, size } = props

  return (
    <FaCss3 title={title} size={size} />
  )
}

export default FaCss3Icon
