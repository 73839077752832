import React from 'react'
import { Wrapper } from './NotFound.styles'

const NotFound = () => {
  return (
    <Wrapper>
      <h1>Ops!</h1>
      <p>Dobleos is not working now!</p>
    </Wrapper>
  )
}

export default NotFound
