import { Css3Icon, Html5Icon, ReactIcon, WordpressIcon } from './index'

const ICON_TYPES = Object.freeze({
  CSS: 'CSS',
  HTML: 'HTML',
  REACT: 'REACT',
  WORDPRESS: 'WORDPRESS'
})

const ICON_SIZE = 42

export const renderIcon = type => {
  switch (type) {
    case ICON_TYPES.CSS:
      return <Css3Icon title={ICON_TYPES.CSS} size={ICON_SIZE} />
    case ICON_TYPES.HTML:
      return <Html5Icon title={ICON_TYPES.HTML} size={ICON_SIZE} />
    case ICON_TYPES.REACT:
      return <ReactIcon title={ICON_TYPES.REACT} size={ICON_SIZE} />
    case ICON_TYPES.WORDPRESS:
      return <WordpressIcon title={ICON_TYPES.REACT} size={ICON_SIZE} /> // TODO: fix title
    default:
      break;
  }
}
