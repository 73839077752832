import { FaBarsIcon } from '../../../../components/Icons'
import { Container, PageName } from './Brand.styles'

const Brand = () => {
  return (
    <Container>
      <FaBarsIcon size='20px' title='Menú' />
      <PageName>Dashboard</PageName>
    </Container>
  )
}

export default Brand
