import styled from 'styled-components'

export const MainWrapper = styled.main`
  margin-top: 60px;
  padding: 2rem 1.5rem;
  min-height: calc(100vh - 90px);
`

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  margin-top: 1rem;
`

export const Title = styled.h2``

export const Content = styled.div``
