import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  height: 100vh;

  gap: 1.5rem;
  grid-template-areas:
    'header'
    'nav'
    'main'
    'sidebar'
    'footer';
  grid-template-rows: auto auto 1fr auto auto;

  @media (min-width: 720px) {
    grid-template-areas:
      'header header'
      'nav nav'
      'sidebar main'
      'footer footer';
    grid-template-rows: auto auto 1fr auto;
  }

  @media (min-width: 1020px) {
    grid-template-areas:
      'header header header'
      'sidebar nav nav'
      'sidebar main main'
      'footer footer footer';
    grid-template-rows: auto auto 1fr auto;
  }
`

export const Header = styled.header`
  border: 5px solid red;

  grid-area: header;
`

export const Main = styled.main`
  border: 5px solid red;

  grid-area: main;
`

export const Navigation = styled.nav`
  border: 5px solid red;

  grid-area: nav;
`

export const Sidebar = styled.aside`
  border: 5px solid red;

  grid-area: sidebar;
`

export const Footer = styled.footer`
  border: 5px solid red;

  grid-area: footer;
`
