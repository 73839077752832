import React from 'react'
import { useAuthProvider } from '../AuthProvider/AuthProvider.hook'
import { Logout as LogoutButton } from './Logout.styles'

const Logout = () => {
  const { logout } = useAuthProvider()

  const handleLogout = () => logout()

  return (
    <LogoutButton onClick={handleLogout}>Cerrar sesión</LogoutButton>
  )
}

export default Logout
