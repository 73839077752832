import React from 'react'
import LandingLayout from '../../components/Layouts/LandingLayout'
import AboutContainer from '../../modules/Portafolio/About/About.container'
import FooterContainer from '../../modules/Portafolio/Footer/Footer.container'
import HeaderContainer from '../../modules/Portafolio/Header/Header.container'
import ProjectContainer from '../../modules/Portafolio/Project/Project.container'
import ProtectByFeatures from '../../components/Security/ProtectByFeatures'

/*
 * The Portfolio Home's page
 */
const Landing = (props) => {
  return (
    <LandingLayout header={<HeaderContainer />} footer={<FooterContainer />}>
      <ProtectByFeatures rule={features => features['about.me.section']}>
        <AboutContainer />
      </ProtectByFeatures>
      <ProtectByFeatures
        fallback={() => <p>Esta sección no se encuentra activa</p>}
        rule={features => features['project.section']}
      >
        <ProjectContainer />
      </ProtectByFeatures>
    </LandingLayout>
  )
}

export default Landing
