import { Label } from './Label.styles'

/**
 * The BaseLabel' component.
 */
const BaseLabel = props => {
  const { children } = props

  return (
    <Label>{children}</Label>
  )
}

export default BaseLabel
