import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onGetProfileAsync } from '../AuthProvider/AuthProvider.slice'
import { Container, Field, Photo } from './Profile.styles'
import BaseLabel from '../../../../components/Basics/Label'

const Profile = () => {
  const { username, email, roles = [], photo } = useSelector(state => state.auth.user)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(onGetProfileAsync())
  }, [dispatch])

  return (
    <Container>
      <Field>
        <BaseLabel>
          Usuario: {username}
        </BaseLabel>
      </Field>
      <Field>
        <BaseLabel>
          Correo: {email}
        </BaseLabel>
      </Field>
      <Field>
        <BaseLabel>
          Roles:
          {roles.map((role) => <span key={role}> {role}</span>)}
          </BaseLabel>
      </Field>
      <Field>
        <BaseLabel>
          Foto:
          <Photo src={photo} alt={username} />
        </BaseLabel>
      </Field>
    </Container>
    
  )
}

export default Profile