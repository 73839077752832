import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import LoginContainer from '../LogIn/Login.container'
import LogoutContainer from '../LogOut/Logout.container'
import { Container, GithubContainer } from './Header.styles'
import FaGithubIcon from '../../../components/Icons/FaGithubIcon'
import FaInfinityIcon from '../../../components/Icons/FaInfinityIcon'
import ConfigIcon from '../../../components/Icons/ConfigIcon'

const Header = () => {
  const { isAuthenticated } = useAuth0()
  const handleGithub = () => window.open('https://github.com/oscarossesa', '_blank')

  return (
    <Container>
      <ConfigIcon>
        <FaInfinityIcon title='Home' size='36px' />
      </ConfigIcon>
      <ConfigIcon>
        {!isAuthenticated ? <LoginContainer /> : <LogoutContainer />}      
      </ConfigIcon>
      <GithubContainer onClick={handleGithub}>
        <ConfigIcon>
          <FaGithubIcon title='Github Repository' size='36' />
        </ConfigIcon>
      </GithubContainer>
    </Container>
  )
}

export default Header
