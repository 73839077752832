import React from 'react'
import { Link } from 'react-router-dom'
import HomeLayout from '../../components/Layouts/GabstoreLayout/GabstoreHomeLayout'

const Home = () => {
  return (
    <HomeLayout>
      <div className="row">
        <div className="col">
          <h1 className="mt-4">Bienvenido, escanea el QR</h1>
        </div>
        <div id="qrcode">
          <img
            src="https://www.codigos-qr.com/qr/php/qr_img.php?d=http%3A%2F%2Fwww.ooa.cl%2Ftienda&s=6&e=m"
            alt="Generador de Códigos QR Codes"
          />
          <br />
        </div>
        <h1 className="mt-4 mb-4">ó</h1>
        <div className="mt-5">
          <Link to="/gabstore/store" className="btn btn-primary btn-lg">
            ¡Vamos!
          </Link>
        </div>
      </div>
    </HomeLayout>
  )
}

export default Home
