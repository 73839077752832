import React from 'react'
import { createRoot } from 'react-dom/client'
import { Auth0Provider } from "@auth0/auth0-react"
import { Provider as StoreProvider } from 'react-redux'
import { StyleSheetManager } from 'styled-components'
import { store } from './config/store/store'
import AuthProvider from './modules/Admin/containers/AuthProvider/AuthProvider.container'
import App from './App'

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <StyleSheetManager disableVendorPrefixes>
      <StoreProvider store={store}>
        <Auth0Provider
          domain="dev-us2cchl1.us.auth0.com"
          clientId="xXRxLQUUSwKADDhzNIFo2HX1CuGRcdqt"
          redirectUri={window.location.origin}
        >
          <AuthProvider>
            <App />
          </AuthProvider>
        </Auth0Provider>
      </StoreProvider>
    </StyleSheetManager>
  </React.StrictMode>
)
