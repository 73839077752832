export const listToDict = list => {
  const dict = {}

  list.forEach((element) => dict[element.name] = element.value)

  return dict
}

export const stringToList = (value, separator) => {
  return value.split(separator)
}