import styled from 'styled-components'

export const Button = styled.button`
  background-color: #696cff; // TODO: this prop should be dynamic like primary, secondary, etc.
  border-color: #696cff;
  border-radius: 5px;
  border: none;
  color: #080710; // TODO: this color prop should be dynamic
  color: #566a7f;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  letter-spacing: 0.5px;
  margin: ${props => props.margin || 0};
  outline: none;
  padding: 10px 10px;
  width: ${props => props.width || 'auto'};

  &:active {
    transform: scale(0.98);
  }
`
