import React, { useState, useEffect } from 'react'
import axios from 'axios'
import StoreLayout from '../../components/Layouts/GabstoreLayout/GabstoreLayout'

const Store = () => {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)

      const result = await axios('http://localhost:3001/api/v1/store')
      
      setData(result.data)
      setIsLoading(false)
    }

    fetchData()
  }, [])

  return (
    <StoreLayout>
      <div className="row">
        <div className="col">
          <img src="/images/store/presentacion.jpeg" className="img-fluid" alt="Sazón La Rosa"></img>
          <h3 className="display-5 font-weight-bold text-center pb-4 mt-2">Sazón La Rosa</h3>
        </div>
      </div>
      {isLoading ? (
        <div>Carganado...</div>
      ) : (
        <>
          {data.map(item => (
            <React.Fragment key={item.id}>
              <div className="row" key={item.id}>
                <div className="col-4 text-center">
                  {item.imagen ? (
                    <img className="img-thumbnail" src={`/images/store/${item.imagen}`} alt=""></img>
                  ) : (
                    <img
                      src="/images/store/comer.svg?h=e57104cc1427561c7d89c6004abefe05"
                      className="img-thumbnail"
                      width="70%"
                      alt=""
                    />
                  )}
                </div>
                <div className="col-8">
                  <div className="row">
                    <div className="col">{item.nombre}</div>
                  </div>
                  <div className="row">
                    <div className="col">$ {item.precio}</div>
                  </div>
                  <div className="row">
                    <div className="col mt-5">
                      <span class="badge badge-pill badge-info">¡Pídelo ya! al +56 9 8980 5991.</span>
                    </div>
                  </div>
                </div>
              </div>

              <hr></hr>
            </React.Fragment>
          ))}
        </>
      )}
    </StoreLayout>
  )
}

export default Store
