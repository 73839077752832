import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { goToEditProject, onDeleteProjectAsync, onGetProjectsAsync } from './Project.slice'
import { default as Button } from '../../../../components/Buttons/BaseButton'
import Spinner from '../../../../components/Spinners/Spinner'
import { Container, ButtonWrapper, Table } from './Project.styles'

const ProjectList = () => {
  const { error, loading, projects = [] } = useSelector(state => state.adminProject)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleOnEdit = id => () => {
    dispatch(goToEditProject({ id }))
    navigate('/admin/projects/edit')
  }
  
  const handleOnDelete = id => () => {
    dispatch(onDeleteProjectAsync({ id }))
  }

  useEffect(() => {   
    dispatch(onGetProjectsAsync())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      {loading && <Spinner />}
      {!loading && error && (<p>Error</p>)}
      {!loading && (
        <Table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Nombre</th>
              <th>Descripción</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {projects.map(({ id, description, name }) => (
              <tr key={id}>
                <td>{id}</td>
                <td>{name}</td>
                <td>{description}</td>
                <td>
                  <ButtonWrapper>
                    <Button onClick={handleOnEdit(id)}>Editar</Button>
                    <Button onClick={handleOnDelete(id)}>Eliminar</Button>
                  </ButtonWrapper>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  )
}

export default ProjectList
