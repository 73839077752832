import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { RiLogoutCircleRLine } from 'react-icons/ri';

const Login = () => {
  const { loginWithRedirect } = useAuth0()
  const handleLogin = () => loginWithRedirect()

  return (
    <RiLogoutCircleRLine onClick={handleLogin} title='Cerrar Sesión' size='42' />
  )
}

export default Login
