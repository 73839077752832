import React from 'react'
import { Container, LogoutContainer, UserContainer, UserIcon, Username } from './User.styles'
import Logout from '../Logout/Logout.container'
import { useAuthProvider } from '../AuthProvider/AuthProvider.hook'

const User = () => {
  const { user } = useAuthProvider()

  return (
    <Container>
      <UserIcon src={user?.photo} alt={user?.username} />
        <UserContainer>
          <Username>{user?.username}</Username>
          <LogoutContainer>
            <Logout />
          </LogoutContainer>
        </UserContainer>
    </Container>
  )
}

export default User
