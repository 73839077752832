import React from 'react'
import { FaWordpress } from 'react-icons/fa';

const FaWordpressIcon = (props) => {
  const { title, size } = props

  return (
    <FaWordpress title={title} size={size} />
  )
}

export default FaWordpressIcon
