import { useMemo } from 'react'

/**
 * Abstraction in charge on keep a relationship between unique keys and actions (e.g. functions, Axios' cancellers).
 * It's useful to cancel actions when invoking the same unique key consecutively.
 */
export class Canceling {
  constructor() {
    this.dictionary = {}
  }

  register(key, action) {
    if (!key) {
      return
    }

    this.dictionary = { ...this.dictionary, [key]: action }
  }

  run(key = '') {
    const action = this.dictionary[key]

    if (typeof action !== 'function') {
      return
    }

    action()
  }
}

/**
 * Factory to create a Canceling instance and expose it as a singleton.
 */
const factoryCancelingSingleton = () => {
  const instance = new Canceling()

  return () => instance
}

/**
 * To get the Canceling singleton.
 */
export const cancelingSingleton = factoryCancelingSingleton()

/**
 * The canceling custom hook.
 */
export const useCanceling = () => useMemo(() => cancelingSingleton(), [])
