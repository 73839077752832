import React from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import Spinner from '../../../components/Spinners/Spinner';
import { Avatar, Box, Container, Name, Paragraph, Role, Wrapper } from './About.styles'

const About = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  return (
    <Container>
      <Wrapper>
        {isLoading && <Spinner />}
        <Box>
          {!isAuthenticated ?
            <Paragraph>Hola, soy</Paragraph> :
            <Paragraph>Hola {user.name}, soy</Paragraph>
          }
          <Name>Dobleos</Name>
          <Role>Fullstack Developer</Role>
        </Box>
        <Box>
          <Avatar 
            alt="Avatar de Dobleos"
            height="100%"
            fetchpriority="high"
            src="images/me.webp" 
            type="image/webp"
            width="100%" 
          />
        </Box>
      </Wrapper>
    </Container>
  )
}

export default About
