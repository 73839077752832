import React from 'react'
import PolicyContainer from '../containers/Policy.container'

const Policy = () => {
  return (
    <PolicyContainer />
  )
}

export default Policy
