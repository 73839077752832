import React from 'react'
import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import AddNavigationPage from './modules/Admin/pages/AddNavigation'
import AddProjectPage from './modules/Admin/pages/AddProject'
import ChilecompaPage from './pages/Chilecompa/Chilecompa'
import DashboardPage from './modules/Admin/pages/Dashboard'
import EditProjectPage from './modules/Admin/pages/EditProject'
import GabstoreHomePage from './pages/Gabstore/Home'
import GabstoreStorePage from './pages/Gabstore/Store'
import LandingPage from './pages/Portfolio/Landing'
import LoginPage from './modules/Admin/pages/Login'
import NavigationEditPage from './modules/Admin/pages/NavigationEdit'
import NavigationListPage from './modules/Admin/pages/NavigationList'
import NotFoundPage from './components/Pages/NotFound'
import ProfilePage from './modules/Admin/pages/Profile'
import ProjectPage from './modules/Admin/pages/Project'
import ProtectedByAuthentication from './modules/Admin/containers/AuthProvider/AuthProvider.authentication'
import PetPolicyPage from './modules/AnimalCircle/pages/Policy'
import StickyPage from './pages/Sticky/Sticky'
import routes from './config/settings/routes'

/**
 * The Routing's component.
 */
const routeComponents = [
  <Route caseSensitive element={<LandingPage />} key={routes.landing} path={routes.landing} />,
  <Route caseSensitive element={<LandingPage />} key={routes.root} path={routes.root} />,
  <Route caseSensitive element={<ChilecompaPage />} key={routes.chilecompa} path={routes.chilecompa} />,
  <Route caseSensitive element={<GabstoreHomePage />} key={routes.gabstoreHome} path={routes.gabstoreHome} />,
  <Route caseSensitive element={<GabstoreStorePage />} key={routes.gabstoreStore} path={routes.gabstoreStore} />,
  <Route caseSensitive element={<StickyPage />} key={routes.sticky} path={routes.sticky} />,
  <Route caseSensitive element={<LoginPage />} key={routes.adminLogin} path={routes.adminLogin} />,
  <Route caseSensitive element={<PetPolicyPage />} key={routes.petPolicy} path={routes.petPolicy} />,
  <Route element={<ProtectedByAuthentication />} key='protected'>
    <Route caseSensitive element={<AddNavigationPage />} key={routes.adminNavigationNew} path={routes.adminNavigationNew}  />,
    <Route caseSensitive element={<AddProjectPage />} key={routes.adminProjectsNew} path={routes.adminProjectsNew}  />,
    <Route caseSensitive element={<DashboardPage />} key={routes.adminDashboard} path={routes.adminDashboard} />
    <Route caseSensitive element={<EditProjectPage />} key={routes.adminProjectsEdit} path={routes.adminProjectsEdit}  />,
    <Route caseSensitive element={<NavigationEditPage />} key={routes.adminNavigationEdit} path={routes.adminNavigationEdit}  />,
    <Route caseSensitive element={<NavigationListPage />} key={routes.adminNavigation} path={routes.adminNavigation}  />,
    <Route caseSensitive element={<ProfilePage />} key={routes.adminAccount} path={routes.adminAccount}  />,
    <Route caseSensitive element={<ProjectPage />} key={routes.adminProjects} path={routes.adminProjects}  />,
  </Route>,
  <Route caseSensitive element={<PetPolicyPage />} key={routes.pet} path={routes.pet} />,
  <Route caseSensitive element={<NotFoundPage />} key="*" path="*" />
]

const Routing = createBrowserRouter(createRoutesFromElements(routeComponents))

export default Routing
