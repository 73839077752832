import Logger from 'loglevel'

/**
 * Gets a Logger instance as a singleton.
 */
export const getLoggerSingleton = () => Logger

/**
 * To initialize the Logger tool.
 */
const initializeLogger = ({ environment = 'dev' } = {}) => {
  const isDevEnv = environment !== 'prod'
  const singleton = getLoggerSingleton()

  singleton.setLevel(isDevEnv ? Logger.levels.DEBUG : Logger.levels.WARN)
}

export default initializeLogger
