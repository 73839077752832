import React from 'react'
import { getLogger } from 'loglevel'
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom'
import { useAuthProvider } from './AuthProvider.hook'
import { Navigate, Outlet } from 'react-router-dom'
import routes from '../../../../config/settings/routes'
import { deleteCookie } from '../../../../core/storages/cookies';

const logger = getLogger('ProtectedByAuthentication')

const ProtectedByAuthentication = () => {
  const { accessToken: token, isAuthenticated } = useAuthProvider()
  const navigate = useNavigate()
  
  logger.debug('ProtectedByAuthentication isAuthenticated', isAuthenticated)

  if (!token || !isAuthenticated) { // TODO: how to validate token correcty?
    return <Navigate to={routes.adminLogin} replace />
  }

  if (token) {
    try {
      const decodedToken = jwtDecode(token)
      const currentTime = Date.now() / 1000 // Fecha actual en segundos

      if (decodedToken.exp < currentTime) {
        deleteCookie('access_token')
        navigate(routes.adminLogin)
      }
    } catch (error) {
      // Si ocurre un error al decodificar el token, redirigir al login
      deleteCookie('access_token')
      navigate(routes.adminLogin)
    }
  } else {
    // No hay token, redirigir al login
    navigate(routes.adminLogin);
  }

  return <Outlet />
}

export default ProtectedByAuthentication
