import React from 'react'
import { FaHtml5 } from 'react-icons/fa';

const FaHtml5Icon = (props) => {
  const { title, size } = props

  return (
    <FaHtml5 title={title} size={size} />
  )
}

export default FaHtml5Icon
