import React from 'react'
import PropTypes from 'prop-types'
import { Container, Message } from './MessageNotice.styles'

const MessageNotice = (props) => {
  const {
    children,
    type
  } = props

  return (
    <Container>
      <Message type={type}>
        {children}
      </Message>
    </Container>
  )
}

MessageNotice.defaultProps = {
  type: 'error'
}

MessageNotice.propTypes = {
  /**
   * Children.
   */
  children: PropTypes.node.isRequired,
  /**
   * Type.
   */
  type: PropTypes.oneOf(['error', 'info', 'success', 'warning'])
}

export default MessageNotice
