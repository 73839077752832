import { createContext } from 'react'

const contextPlayload = {
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
  user: undefined,
  accessToken: null,
  loading: false,
  error: false
}

const AuthProviderContext = createContext(contextPlayload)
AuthProviderContext.displayName = 'AuthProviderContext'

export default AuthProviderContext
