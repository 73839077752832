import styled from 'styled-components'

export const Container = styled.div`
  border: 1px solid;
  border-radius: 50%;
  color: ${props => props.theme.text3};
  opacity: 80%;

  & > svg {
    border: 1px solid;
    border-radius: 50%;
    font-size: .5rem;
    vertical-align: middle;
    padding: 10px;
  }
`
