import styled from 'styled-components'

export const Container = styled.div`
  margin: 20px 0;
` 

export const Message = styled.div`
  background-color: #ffffff;
  padding: 15px;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  border-left-color: ${props => {
    if (props.type === 'error') {
      return '#d9534f';
    }
    if (props.type === 'warning') {
      return '#f0ad4e';
    }
    if (props.type === 'info') {
      return '#5bc0de';
    }
    if (props.type === 'success') {
      return '#2b542c';
    }
  }};
  background-color: ${props => {
    if (props.type === 'error') {
      return 'rgba(217, 83, 79, 0.1)'; /* Same color as the left border with reduced alpha to 0.1 */
    }
    if (props.type === 'warning') {
      return 'rgba(240, 173, 78, 0.1)'; /* Same color as the left border with reduced alpha to 0.1 */
    }
    if (props.type === 'info') {
      return 'rgba(91, 192, 222, 0.1)'; /* Same color as the left border with reduced alpha to 0.1 */
    }
    if (props.type === 'success') {
      return 'rgba(43, 84, 44, 0.1)'; /* Same color as the left border with reduced alpha to 0.1 */
    }
  }};
`















