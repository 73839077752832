import React, { useCallback, useState } from 'react'
import { Navigate } from 'react-router-dom'
import routes from '../../../../config/settings/routes'
import { useAuthProvider } from '../AuthProvider/AuthProvider.hook'
import { default as Button } from '../../../../components/Buttons/BaseButton'
import { default as Label } from '../../../../components/Basics/Label'
import { default as Input } from '../../../../components/Inputs/BaseInput'
import MessageNotice from '../../../../components/MessageNotice/MessageNotice'
import { Form, Title } from './Login.styles'

const Login = () => {
  const [user, setUser] = useState({ username: '', password: ''})
  const { isAuthenticated, login, loading, error, accessToken: token } = useAuthProvider()

  const handleLogin = useCallback(event => {
    event.preventDefault()
    login(user)
  }, [login, user])

  const handleChange = ({ target: { name, value } }) => setUser(inputs => ({...inputs, [name]: value}))
    
  if (!loading && token && isAuthenticated) {
    return <Navigate to={routes.adminDashboard} />;
  }

  return (
    <>
      <Title>Ingresar</Title>
      <Form>
        <Label>Usuario</Label>
        <Input
          type='text'
          onChange={handleChange}
          name='username'
          placeholder='Usuario'
        />
        <Label>Contraseña</Label>
        <Input
          type='password'
          onChange={handleChange}
          name='password'
          placeholder='***********'
        />
        {error && <MessageNotice>Usuario no encontrado.</MessageNotice>}
        <Button
          disabled={!user.username || !user.password}
          margin='20px 0 0 0'
          type='submit'
          onClick={handleLogin}
          width='100%'
        >
          {!loading ? 'Entrar' : 'Cargando...'}
        </Button>
      </Form>
    </>
  )
}

export default Login
