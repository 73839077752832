import { REACT_APP_API_BASE_URL } from '../config/settings/environment'
import { getJsonRequest } from '../core/requests/requests'
import { factoryService } from '../core/requests/services'

/**
 * Gets the list of application config.
 * TODO: these data could be a dictionary, currently is an array of objects.
 */
export const getApplications = factoryService(() => 
  getJsonRequest(`${REACT_APP_API_BASE_URL}/applications`)
)

/**
 * Gets the status of the application.
 */
export const getApplicationStatus = factoryService(() => 
  getJsonRequest(`${REACT_APP_API_BASE_URL}/`)
)

