import React from 'react'
import { FaBars } from 'react-icons/fa'

const FaBarsIcon = (props) => {
  const { title, size, style } = props

  return (
    <FaBars title={title} size={size} style={style} />
  )
}

export default FaBarsIcon
