import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100vh;
`

Wrapper.displayName = 'StyledWrapper'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #d9dee3;
  padding: 20px 20px;
  margin: 0 10px;
  max-width: 400px;
  width: 100vw;
`

Container.displayName = 'StyledContainer'
