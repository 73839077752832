import styled from 'styled-components'

export const Title = styled.h3`
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
  color: #566a7f;
`

export const Form = styled.form``
