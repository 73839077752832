import styled from 'styled-components'
import breakpoints from '../../config/settings/breakpoints'

export const Container = styled.div`
  background-color: ${(props) => props.theme.background};
  display: grid;
  gap: 1rem;
  grid-template-areas:
    'header'
    'toggle-theme'
    'aboutMe'
    'projects'
    'footer';
  grid-template-columns: 1fr;

  @media ${breakpoints.device.sm} {
    grid-template-areas:
      'header header header'
      'toggle-theme toggle-theme toggle-theme'
      '. aboutMe .'
      '. projects .'
      '. footer .';
    grid-template-columns: 1fr minmax(0, 1280px) 1fr;
  }
`

export const Header = styled.header`
  display: grid;
  grid-area: header;
  grid-template-areas: '. header-content .';
  grid-column: 1 / -1;
  grid-template-columns: 1fr minmax(0, 1280px) 1fr;
  inset-block-start: 0;
  position: sticky;
`

export const Footer = styled.footer`
  display: grid;
  grid-template-areas: '. footer-content .';
  grid-area: footer;
  grid-column: 1 / -1;
  grid-template-columns: 1fr minmax(0, 1280px) 1fr;
  margin-block-start: 1rem;
`
