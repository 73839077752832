import React from 'react'
import { FaInfinity } from 'react-icons/fa';

const FaInfinityIcon = (props) => {
  const { title, size } = props

  return (
    <FaInfinity title={title} size={size} />
  )
}

export default FaInfinityIcon
