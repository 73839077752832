const routes = {
  adminAccount: '/admin/account',
  adminCustomers: '/admin/customers',
  adminDashboard: '/admin/dashboard',
  adminInventory: '/admin/inventory',
  adminLogin: '/admin/login',
  adminNavigation: '/admin/navigation',
  adminNavigationEdit: '/admin/navigation/edit',
  adminNavigationNew: '/admin/navigation/new',
  adminOrders: '/admin/orders',
  adminProjects: '/admin/projects',
  adminProjectsEdit: '/admin/projects/edit',
  adminProjectsNew: '/admin/projects/new',
  adminTasks: '/admin/tasks',
  chilecompa: '/chilecompa',
  gabstoreHome: '/gabstore',
  gabstoreStore: '/gabstore/store',
  landing: '/landing',
  petPolicy: '/pet-circle/policy',
  pet: '/pet/policy',
  root: '/',
  sticky: '/labs/sticky'
}

export default routes
