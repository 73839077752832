import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { onGetAuthAsync, onGetProfileAsync, logout as onLogout } from './AuthProvider.slice'
import AuthContext from './AuthProvider.context'
import { getCookie } from '../../../../core/storages/cookies'
import routes from '../../../../config/settings/routes'

const AuthProvider = ({ children }) => {
  const { user, loading: authLoading, error } = useSelector(state => state.auth)
  const accessToken = getCookie('access_token')
  const isAuthenticated = getCookie('is_authenticated')
  const dispatch = useDispatch()
  
  const login = useCallback((user) => {
    dispatch(onGetAuthAsync(user))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const logout = useCallback(() => {
    dispatch(onLogout())

    window.location.replace(routes.adminLogin)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (accessToken && isAuthenticated) {
      dispatch(onGetProfileAsync())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  return (
    <AuthContext.Provider value={{ isAuthenticated, loading: authLoading, login, logout, user, accessToken, error }}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default AuthProvider
