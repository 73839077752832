import styled from 'styled-components'

export const Container = styled.section`
  grid-area: projects;
  margin-inline-end: 1rem;
  margin-inline-start: 1rem;
  margin-top: 3rem;
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 2em;
  margin-top: 2.5rem;
`

export const Title = styled.h1`
  margin-bottom: 24px;
  text-transform: uppercase;
  text-align: center;
`

export const CardBody = styled.div`
  grid-area: text;
  border-top: 1px solid ${props => props.theme.text2};
`

export const CardDescription = styled.p`
  color: ${props => props.theme.text3};
  padding-inline-start: 20px;
  padding-inline-end: 20px;
  margin-bottom: 20px;
`

export const CardTitle = styled.p`
  color: ${props => props.theme.text3};
  margin-bottom: 16px;
  font-size: 1.125rem;
  font-weight: bold;
`

export const CardChips = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-block-start: 1.5rem;
  margin-block-end: 1.5rem;
`
