import { BaseTitle, Container } from './Title.styles'

const Title = props => {
  const { children } = props

  return (
    <Container>
      <BaseTitle>{children}</BaseTitle>
    </Container>
  )
}

export default Title
