import styled from 'styled-components'
import breakpoints from '../../../config/settings/breakpoints'

export const Avatar = styled.img`
  border-radius: 50%;
  margin-block-start: 1rem;
  width: 200px;

  @media ${breakpoints.device.sm} {
    width: 300px;
  }
`

export const Box = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const Container = styled.section`
  grid-area: aboutMe;
  margin-inline-start: 1rem;
  margin-inline-end: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const Name = styled.span`
  display: inline-block;
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;

  @media ${breakpoints.device.sm} {
    font-size: 3rem;
  }

  @media ${breakpoints.device.lg} {
    font-size: 3.5rem;
  }
`

export const Paragraph = styled.p`
  margin-block-end: 0;
  margin-block-start: 0;
  font-size: 1.5rem;
  text-transform: uppercase;
`

export const Role = styled.p`
  color: ${props => props.theme.text3};
  font-size: 1rem;
  margin-block-end: 0;
  margin-block-start: 0;
`

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`
