import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onGetNavigationAsync } from './Sidebar.slice'
import Link from '../../../../components/Navigation/Link'
import Brand from './Sidebar.brand'
import {
  Container,
  LinkName,
  SidebarMenuContainer,
  Nav,
  NavbarLinkContainer,
  NavbarMenu
} from './Sidebar.styles'

const Sidebar = () => {
  const { navigationOptions } = useSelector(state => state.sidebar)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(onGetNavigationAsync())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Brand name='DOBLEOS' />
      <SidebarMenuContainer>
        <Nav>
          <NavbarLinkContainer>
            {navigationOptions.map(({id, name, path, styles, isActive}) =>
              isActive && <NavbarMenu key={id}>
                <Link path={path}>
                  <span className={styles}></span>
                  <LinkName>{name}</LinkName>
                </Link>
              </NavbarMenu>
            )}
          </NavbarLinkContainer>
        </Nav>                        
      </SidebarMenuContainer>
    </Container>
  )
}

export default Sidebar
