import styled from 'styled-components'

const COLOR_WHITE = '#FFFFFF';
const MAIN_COLOR = '#DD2F6E';
const COLOR_GREY = '#8390A2';

export const CardSingle = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${COLOR_WHITE};
  padding: 2rem;
  border-radius: 2px;

  & div:last-child span {
    font-size: 3rem;
    color: ${MAIN_COLOR};
  }

  & div:first-child span {
    color: ${COLOR_GREY};
  }

  &:last-child {
    background: ${MAIN_COLOR};
  }

  &:last-child h1 {
    background: ${MAIN_COLOR};
  }

  &:last-child h1,
  &:last-child div:first-child span,
  &:last-child div:last-child span {
    color: ${COLOR_WHITE};
  }
`