import styled from 'styled-components'

export const Container = styled.section`
  margin: 32px 0;
`

export const Form = styled.form``

export const Table = styled.table`
  width: 100%;
`

export const Title = styled.h1`
  margin-bottom: 24px;
  text-transform: uppercase;
  text-align: center;
`

export const CardBody = styled.div`
  grid-area: text;
  border-top: 1px solid ${props => props.theme.text2};
`

export const CardDescription = styled.p`
  color: ${props => props.theme.text3};
  padding-inline-start: 20px;
  padding-inline-end: 20px;
  margin-bottom: 20px;
`

export const CardTitle = styled.p`
  color: ${props => props.theme.text3};
  margin-bottom: 16px;
  font-size: 1.125rem;
  font-weight: bold;
`

export const CardChips = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-block-start: 1.5rem;
  margin-block-end: 1.5rem;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`
