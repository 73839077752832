import Axios from 'axios'
import AxiosRetry, {
  exponentialDelay,
  isIdempotentRequestError,
  isNetworkError,
  isNetworkOrIdempotentRequestError,
  isRetryableError
} from 'axios-retry'
import getLogger from '../debuggers/logger'
import { getCookie } from '../storages/cookies'

const logger = getLogger('Requests')

export const RETRY_BEHAVIORS = {
  '5xx': '5XX_ERROR',
  idempotent: 'IDEMPOTENT_ERROR',
  network: 'NETWORK_ERROR'
}

/**
 * Gets the request's headers.
 */
const getHeaders = (args = {}) => {
  const { contentType, customHeaders = {}, isPublic = false, token } = args
  let headers = { ...customHeaders }

  if (contentType) {
    headers = { ...headers, 'Content-Type': contentType }
  }

  if (isPublic) {
    return headers
  }

  const accessToken = token || getCookie('access_token') || ''

  return { ...headers, Authorization: `Bearer ${accessToken}` }
}

/**
 * Gets a form data object.
 */
const getFormData = (body = {}) => {
  const formData = new FormData()

  Object.keys(body).forEach(key => {
    if (!Array.isArray(body[key])) {
      formData.append(key, body[key])

      return
    }

    body[key].forEach(item => formData.append(key, item))
  })

  return formData
}

/**
 * Makes request using the Axios API.
 *
 * Generate a caller with the following signature:
 *
 *  interface RequestCallerOptions {
 *    isPublic?: boolean;
 *    retries?: number;
 *    retryBehavior?: '5XX_ERROR' | 'IDEMPOTENT_ERROR' | 'NETWORK_ERROR';
 *    [key: string]: any;
 *  }
 *  interface RequestCallerResponse {
 *    data: object;
 *    headers: object;
 *    request: object;
 *    status: number;
 *  }
 *
 *  type RequestCaller = (url: string, options: RequestCallerOptions) => Promise<RequestCallerResponse>
 *
 * E.g.
 *  # Making a GET request with no retries.
 *  getJsonRequest('https://api.example.com/auth', { isPublic: true })
 *
 *  # Making a GET request with two retries at 5xx, network and idempotent errors (default when retries are set but retryBehavior doesn't).
 *  getJsonRequest('https://api.example.com/auth', { isPublic: true, retries: 2 })
 *
 *  # Making a POST request with two retries at only 5xx errors.
 *  postJsonRequest('https://api.example.com/auth', { isPublic: true, retries: 2, retryBehavior: '5XX_ERROR' })
 *
 *  # Making a GET request with two retries at only network errors.
 *  getJsonRequest('https://api.example.com/auth', { isPublic: true, retries: 2, retryBehavior: 'NETWORK_ERROR' })
 *
 *  # Making a GET request with two retries at only idempotent errors.
 *  getJsonRequest('https://api.example.com/auth', { isPublic: true, retries: 2, retryBehavior: 'IDEMPOTENT_ERROR' })
 */
const makeRequest = (url, options = {}, features = {}, log = () => undefined) => {
  const { callback = () => undefined, retries = 0, retryBehavior } = features
  const request = Axios.create({ url })

  AxiosRetry(request, {
    retries,
    retryCondition: requestError => {
      if (retryBehavior === RETRY_BEHAVIORS['5xx']) {
        return isRetryableError(requestError)
      }

      if (retryBehavior === RETRY_BEHAVIORS.idempotent) {
        return isIdempotentRequestError(requestError)
      }

      if (retryBehavior === RETRY_BEHAVIORS.network) {
        return isNetworkError(requestError)
      }

      return isNetworkOrIdempotentRequestError(requestError)
    },
    retryDelay: retries && exponentialDelay(retries)
  })

  return (actionType, canceling) =>
    request(url, {
      ...options,
      cancelToken: new Axios.CancelToken(canceller => canceling?.register && canceling?.register(actionType, canceller))
    })
      .then(response => ({
        data: response.data,
        headers: response.headers,
        request: response.config,
        status: response.status
      }))
      .then(response => {
        log(response)
        callback(response)

        return response
      })
}

/**
 * Does a DELETE request with form data as content type.
 */
export const deleteFormRequest = (url, options = {}) => {
  const { body, customHeaders, isPublic, token, ...features } = options
  const headers = getHeaders({ customHeaders, isPublic, token })
  const formData = getFormData(body)

  return makeRequest(url, { headers, data: formData, method: 'DELETE' }, features, response => {
    logger.debug('DELETE FORM request', { headers, options, response, url })
  })
}

/**
 * Does a DELETE request with JSON as content type.
 */
export const deleteJsonRequest = (url, options = {}) => {
  const { body, customHeaders, isPublic, token, ...features } = options
  const headers = getHeaders({ customHeaders, isPublic, token, contentType: 'application/json' })
  const data = body && JSON.stringify(body)

  return makeRequest(url, { data, headers, method: 'DELETE' }, features, response => {
    logger.debug('DELETE JSON request', { data, headers, options, response, url })
  })
}

/**
 * Does a GET file request with JSON as content type and response type as blob.
 */
export const getFileRequest = (url, options = {}) => {
  const { customHeaders, isPublic, token, ...features } = options
  const headers = getHeaders({ customHeaders, isPublic, token, contentType: 'application/json' })

  return makeRequest(url, { headers, method: 'GET', responseType: 'blob' }, features, response => {
    logger.debug('GET FILE request', { headers, options, response, url })
  })
}

/**
 * Does a GET request with JSON as content type.
 */
export const getJsonRequest = (url, options = {}) => {
  const { customHeaders, isPublic, token, ...features } = options
  const headers = getHeaders({ customHeaders, isPublic, token, contentType: 'application/json' })

  return makeRequest(url, { headers, method: 'GET' }, features, response => {
    logger.debug('GET JSON request', { headers, options, response, url })
  })
}

/**
 * Does a PATCH request with form data as content type.
 */
export const patchFormRequest = (url, options = {}) => {
  const { body, customHeaders, isPublic, token, ...features } = options
  const headers = getHeaders({ customHeaders, isPublic, token })
  const formData = getFormData(body)

  return makeRequest(url, { headers, data: formData, method: 'PATCH' }, features, response => {
    logger.debug('PATCH FORM request', { headers, options, response, url })
  })
}

/**
 * Does a PATCH request with JSON as content type.
 */
export const patchJsonRequest = (url, options = {}) => {
  const { body, customHeaders, isPublic, token, ...features } = options
  const headers = getHeaders({ customHeaders, isPublic, token, contentType: 'application/json' })
  const data = body && JSON.stringify(body)

  return makeRequest(url, { data, headers, method: 'PATCH' }, features, response => {
    logger.debug('PATCH JSON request', { data, headers, response, url })
  })
}

/**
 * Does a POST request with form data as content type.
 */
export const postFormRequest = (url, options = {}) => {
  const { body, customHeaders, isPublic, token, ...features } = options
  const headers = getHeaders({ customHeaders, isPublic, token })
  const formData = getFormData(body)

  return makeRequest(url, { headers, data: formData, method: 'POST' }, features, response => {
    logger.debug('POST FORM request', { headers, options, response, url })
  })
}

/**
 * Does a POST request with JSON as content type.
 */
export const postJsonRequest = (url, options = {}) => {
  const { body, customHeaders, isPublic, token, ...features } = options
  const headers = getHeaders({ customHeaders, isPublic, token, contentType: 'application/json' })
  const data = body && JSON.stringify(body)

  return makeRequest(url, { data, headers, method: 'POST' }, features, response => {
    logger.debug('POST JSON request', { data, headers, options, response, url })
  })
}

/**
 * Does a PUT request with form data as content type.
 */
export const putFormRequest = (url, options = {}) => {
  const { body, customHeaders, isPublic, token, ...features } = options
  const headers = getHeaders({ customHeaders, isPublic, token })
  const formData = getFormData(body)

  return makeRequest(url, { headers, data: formData, method: 'PUT' }, features, response => {
    logger.debug('PUT FORM request', { headers, options, response, url })
  })
}

/**
 * Does a PUT request with JSON as content type.
 */
export const putJsonRequest = (url, options = {}) => {
  const { body, customHeaders, isPublic, token, ...features } = options
  const headers = getHeaders({ customHeaders, isPublic, token, contentType: 'application/json' })
  const data = body && JSON.stringify(body)

  return makeRequest(url, { data, headers, method: 'PUT' }, features, response => {
    logger.debug('PUT JSON request', { data, headers, options, response, url })
  })
}
