import { current, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { deleteProject, createProject, getProjects, updateProject } from '../../services/projects'
import { stringToList } from '../../../../config/resources/utils'

const initialState = {
  error: null,
  loading: false,
  projects: [],
  status: 'idle'
}

/**
 * Gets the list of navigation options.
 */
export const onGetProjectsAsync = createAsyncThunk('adminProject/getProjects', async () => {
  const { data } = await getProjects()
  return data;
})

/**
 * Create a new navigation option.
 */
export const onCreateProjectAsync = createAsyncThunk('adminProject/createProject', async ({ ...payload }, { dispatch }) => {
  await createProject(payload)
  dispatch(onGetProjectsAsync())
})

/**
 * Delete a navigation option.
 */
export const onDeleteProjectAsync = createAsyncThunk('adminProject/deleteProject', async ({ id }, { dispatch }) => {
  const response = await deleteProject({ id })
  dispatch(onGetProjectsAsync())
  return response
})

/**
 * Update a navigation option.
 */
export const onEditProjectAsync = createAsyncThunk('adminProject/editProject', async ({ id, updatedProject }, { getState, dispatch }) => {
  const state = getState()
  const response = await updateProject({ id, updatedProject })
  
  const index = state.adminProject.projects.findIndex(item => item.id === id)

  if (index !== -1) {
    // Create a copy of the array and update the project
    const updatedProjects = [...state.adminProject.projects]

    updatedProjects[index] = { ...updatedProjects[index], ...updatedProject }

    // Return the updated projects
    return updatedProjects
  }

  return response
})

export const projectSlice = createSlice({
  name: 'adminProject',
  initialState,
  reducers: { 
    
    goToEditProject(state, action) {
      const { id } = action.payload
      const { projects } = current(state)
      state.projectToEdit = projects.find(project => project.id === id)
    }

  },
  extraReducers: (builder) => {
    
    builder.addCase(onGetProjectsAsync.fulfilled, (state, action) => {
      state.loading = false

      action.payload.map(project => {
        project.technologiesArray = stringToList(project.technologies.toUpperCase(), "|")
        return project
      })

      state.projects = action.payload
    })

    builder.addCase(onCreateProjectAsync.fulfilled, (state) => {
      state.loading = false
      state.status = 'fulfilled'
    })

    builder.addCase(onDeleteProjectAsync.fulfilled, (state, action) => {
      state.projects = state.projects.filter((project) => project.id !== action.payload.id)
      state.status = 'fulfilled'
    })

    builder.addCase(onEditProjectAsync.fulfilled, (state, action) => {
      state.projects = action.payload
      state.status = 'fulfilled'
    })

  }
})

export const { goToEditProject, onEditProject } = projectSlice.actions

export default projectSlice.reducer
