import React from 'react'
import * as Styled from './Card.styles'

const Card = (props) => {
  const { counter, css, name } = props

  return (
    <Styled.CardSingle>
      <div>
        <h1>{counter}</h1>
        <span>{name}</span>
      </div>
      <div>
        <span className={css}></span>
      </div>
    </Styled.CardSingle>
  )
}

export default Card