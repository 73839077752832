import { useNavigate } from 'react-router-dom'
import routes from '../../../../config/settings/routes'
import {
  SidebarBrand,
  BrandName
} from './Sidebar.styles'

const Brand = props => {
  const { name } = props
  const navigate = useNavigate()

  const handleOnGoInit = () => {
    navigate(routes.adminDashboard)
  }

  return (
    <SidebarBrand onClick={handleOnGoInit}>
      <BrandName>{name}</BrandName>
    </SidebarBrand>
  )
}

export default Brand
