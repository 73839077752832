import { configureStore } from '@reduxjs/toolkit'
import adminNavigationReducer from '../../modules/Admin/containers/Navigation/Navigation.slice'
import adminProjectReducer from '../../modules/Admin/containers/Project/Project.slice'
import applicationReducer from './application.slice'
import authReducer from '../../modules/Admin/containers/AuthProvider/AuthProvider.slice'
import projectReducer from '../../modules/Portafolio/Project/Project.slice'
import sidebarReducer from '../../modules/Admin/containers/Sidebar/Sidebar.slice'

export const store = configureStore({
  reducer: {
    adminNavigation: adminNavigationReducer,
    adminProject: adminProjectReducer,
    applications: applicationReducer,
    auth: authReducer,
    projects: projectReducer,
    sidebar: sidebarReducer,
  },
  devTools: true
})
