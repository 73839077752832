import React from 'react'
import { Input, ElLabel } from './CustomInput.styles'

const CustomInput = props => {
  const {
    defaultValue,
    label,
    name,
    onChange,
    placeholder,
    type
  } = props

  return (
    <>
      {label && <ElLabel>{label}</ElLabel>}
      <Input 
        defaultValue={defaultValue}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        type={type} 
      />
    </>
  )
}

export default CustomInput
