import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  justify-content: space-between;
  grid-area: header-content;
  margin-block-end: 1rem;
  margin-block-start: 1rem;
  margin-inline-end: 1rem;
  margin-inline-start: 1rem;
`

export const GithubContainer = styled.div``
