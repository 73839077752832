import React from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from '../../../components/Layouts/AdminLayout'
import { default as Button } from '../../../components/Buttons/BaseButton'
import HeaderContainer from '../containers/Header/Header.container'
import SidebarContainer from '../containers/Sidebar/Sidebar.container'
import ListProjectsContainer from '../containers/Project/ProjectList.container'
import Title from '../containers/MainSection/Title'
import Description from '../containers/MainSection/Description'

const Project = () => {
  const navigate = useNavigate()
  const handleCreateProject = () => navigate('new')

  return (
    <Layout
      header={<HeaderContainer />}
      sidebar={<SidebarContainer />}
      title={<Title>Administración de Proyectos</Title>}
      description={<Description>Agregue, modifique o elimine proyectos.</Description>}
    >
      <Button onClick={handleCreateProject}>Agregar Proyecto</Button>
      <ListProjectsContainer />
    </Layout>
  )
}

export default Project
