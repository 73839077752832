import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const Link = styled(NavLink)`
  display: flex;
  align-items: center;
  font-size: 1.1rem;

  & span:first-child {
    font-size: 1.5rem;
    padding-right: 1rem;
    color: ${props => props.theme.text4};
  }
`
