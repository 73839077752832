import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { onCreateProjectAsync } from './Project.slice.js'
import { default as Button } from '../../../../components/Buttons/BaseButton.jsx'
import { default as Input } from '../../../../components/Inputs/CustomInput.jsx'
import { Container, Form } from './Project.styles.js'
import routes from '../../../../config/settings/routes.js'

const AddProject = () => {
  const [project, setProject] = useState({
    name: '',
    description: '',
    image: '',
    technologies: '',
    link: '',
    type: ''
  })

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleChange = event => {
    const {name, value} = event.target
    setProject(inputs => ({...inputs, [name]: value}))
  }
  
  const handleSaveProject = event => {
    event.preventDefault()

    dispatch(onCreateProjectAsync(project))
    navigate(routes.adminProjects)
  }

  return (
    <Container>
      <Form onSubmit={handleSaveProject}>
        <Input
          label='Nombre'
          name='name'
          onChange={handleChange}
          placeholder='name' 
        />
        <Input
          label='Descripción'
          name='description'
          onChange={handleChange}
          placeholder='description'
        />
        <Input
          label='Imagen'
          name='image'
          onChange={handleChange}
          placeholder='image'
        />
        <Input
          label='Tecnologías'
          name='technologies'
          onChange={handleChange}
          placeholder='technologies'
        />
        <Input
          label='Link'
          name='link'
          onChange={handleChange}
          placeholder='link'
        />
        <Input
          label='Tipo'
          name='type'
          onChange={handleChange}
          placeholder='type'
        />
        <Button
          margin='16px 0'
          type='submit'>
          Guardar
        </Button>
      </Form>
    </Container>
  )
}

export default AddProject
