import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { onEditProjectAsync } from './Project.slice'
import { default as Button } from '../../../../components/Buttons/BaseButton'
import { default as Input } from '../../../../components/Inputs/CustomInput'
import routes from '../../../../config/settings/routes'
import { Container, Form } from './Project.styles'

const EditProject = () => {
  const { projectToEdit } = useSelector(state => state.adminProject)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [id] = useState(projectToEdit?.id)
  const [name, setName] = useState(projectToEdit?.name)
  const [description, setDescription] = useState(projectToEdit?.description)
  const [image, setImage] = useState(projectToEdit?.image)
  const [technologies, setTechnologies] = useState(projectToEdit?.technologies)
  const [link, setLink] = useState(projectToEdit?.link)
  const [type, setType] = useState(projectToEdit?.type)

  const handleName = event => setName(event.target.value);
  const handleDescription = event => setDescription(event.target.value);
  const handleImage = event => setImage(event.target.value);
  const handleTechnologies = event => setTechnologies(event.target.value);
  const handleLink = event => setLink(event.target.value);
  const handleType = event => setType(event.target.value);

  const handleEditProject = event => {
    event.preventDefault()

    const updatedProject = { name, description, image, technologies, link, type }

    dispatch(onEditProjectAsync({ id, updatedProject }))
    navigate(routes.adminProjects)
  }

  return (
    <Container>
      <Form onSubmit={handleEditProject}>
        <Input
          label='Nombre'
          onChange={handleName} 
          laceholder='name'
          defaultValue={name} />
        <Input
          label='Descripción'
          onChange={handleDescription}
          placeholder='description'
          defaultValue={description} />
        <Input
          label='Imagen'
          onChange={handleImage}
          placeholder='image'
          defaultValue={image} />
        <Input
          label='Tecnologías'
          onChange={handleTechnologies}
          placeholder='technologies'
          defaultValue={technologies} />
        <Input
          label='Link'
          onChange={handleLink}
          placeholder='link'
          defaultValue={link} />
        <Input
          label='Tipo'
          onChange={handleType}
          placeholder='type'
          defaultValue={type} />
        <Button margin="30px 0" type='submit'>Guardar</Button>
      </Form>
    </Container>
  )
}

export default EditProject
