import { REACT_APP_API_BASE_URL } from '../../../config/settings/environment'
import { factoryService, deleteJsonRequest, getJsonRequest, postJsonRequest, putJsonRequest } from '../../../core/requests-v2'

/**
 * Gets the list of projects.
 */
export const getProjects = factoryService(({ token }) => {
  return getJsonRequest(`${REACT_APP_API_BASE_URL}/projects`)
})

/**
 * Create a new project.
 */
export const createProject = factoryService(({ ...payload }) => {
  const body = {
    name: payload.name,
    description: payload.description,
    image: payload.image,
    link: payload.link,
    technologies: payload.technologies,
    type: payload.type
  }

  return postJsonRequest(`${REACT_APP_API_BASE_URL}/projects`, { body })
})

/**
 * Delete a project
 */
export const deleteProject = factoryService(({ id }) => {
  return deleteJsonRequest(`${REACT_APP_API_BASE_URL}/projects/${id}`)
})

/**
 * Update a project
 */
export const updateProject = factoryService(({ id, updatedProject }) => {
  const body = {
    name: updatedProject.name,
    description: updatedProject.description,
    image: updatedProject.image,
    link: updatedProject.link,
    technologies: updatedProject.technologies,
    type: updatedProject.type
  }

  return putJsonRequest(`${REACT_APP_API_BASE_URL}/projects/${id}`, { body })
})