import React from 'react'
import BrandLayout from '../../components/Layouts/ChilecompaLayout/ChilecompaLayout'
import FooterContainer from '../../modules/Chilecompa/FooterContainer'
import HeaderContainer from '../../modules/Chilecompa/HeaderContainer'
import MainContainer from '../../modules/Chilecompa/MainContainer'
import NavigationContainer from '../../modules/Chilecompa/NavigationContainer'
import SidebarContainer from '../../modules/Chilecompa/SidebarContainer'

const Chilecompa = () => {
  return (
    <BrandLayout
      footer={<FooterContainer />}
      header={<HeaderContainer />}
      navigation={<NavigationContainer />}
      sidebar={<SidebarContainer />}
    >
      <MainContainer />
    </BrandLayout>
  )
}

export default Chilecompa
