import React from 'react'
import { FaGithub } from 'react-icons/fa';

const FaGithubIcon = (props) => {
  const { title, size } = props

  return (
    <FaGithub title={title} size={size} />
  )
}

export default FaGithubIcon
