import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { useDarkMode } from './components/Theme/Theme.hook'
import GlobalStyle, { darkTheme, lightTheme } from './components/Theme/globalStyle'
import Routing from './Routes'

function App() {
  const [theme] = useDarkMode()
  const themeMode = theme === 'light' ? lightTheme : darkTheme

  return (
    <ThemeProvider theme={themeMode}>
      <GlobalStyle />
      <RouterProvider router={Routing} />
    </ThemeProvider>
  )
}

export default App
